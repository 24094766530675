/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
           
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">

                <div className="container">

                    {/* <div className="openplft">
                        <img
                            src={
                                post.acf.open_play_image_left.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="nonprofitimg"
                            loading="lazy"
                        />
                    </div> */}

                    <div
                        className="openprgt middle-text"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                    <div className='width-fit'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<div class="elfsight-app-a7b470e3-f970-4b8c-8f85-76349c365cd6" data-elfsight-app-lazy></div>`,
                            }}
                        />
                    </div>
                    

                    <div className="clearall"></div>
					{/* <div className="oplayhealwrap">
					<div className="one_half lftoplayheal">
					<h5 class="bluetxt">Pre-booked open play</h5>
					<iframe
                            src="https://werockthespectrumatlantagym.wrtsfranchise.com/prebookedopenplay.php"
                            title="appointment"
                            className="healcode_widgets"
                        />
						
					
					</div>
					
					<div className="one_half">
					<h5 class="bluetxt">Private facility rental</h5>
					<iframe
                            src="https://werockthespectrumatlantagym.wrtsfranchise.com/privatefacilityrental.php"
                            title="appointment"
                            className="healcode_widgets"
                        />
						
					</div>
					</div> */}
                </div>
            </section>
            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
       {/*     <div className="container text_center mt-15">
                <div
                    className="openprgt middle-text"
                    dangerouslySetInnerHTML={{
                        __html: post.acf.open_play_new_healcode_box,
                    }}
                />
            </div>
            <div className="booked_box">
                <a className="heal-bp wrtsbtn yellowbtn open_btned"  data-popup="op_popup">Book Now</a>
            </div>  */}
            {/* <h3 className='bluetxt text_center'>BOOK NOW</h3>
            <div className="oplayhealwrap">
                <iframe className='prebook-iframe' title="appointment" src="https://werockthespectrumatlantagym.wrtsfranchise.com/prebook_openplay.php"
                />
                </div> */}











        {/*        <script src="https://brandedweb.mindbodyonline.com/embed/widget.js" async></script>

            <div className="oplayhealwrap" style={{ paddingTop: '40px' }}>
                <iframe
                    src="https://werockthespectrumatlantagym.wrtsfranchise.com/op_prebook_new.php"
                    className="healcode_widgets_op"
                />
            </div>  */}
            <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script>
                <div className="new-mg-bott"
                    dangerouslySetInnerHTML={{
                        __html: `<div class="elfsight-app-aee63e1e-4a45-4bd0-a739-4d676ed3e5df" data-elfsight-app-lazy></div>`,
                    }}
                />

            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>

            <section className="page-section openphours">
                <div className="container smallestwdt">
                    <div className="twothirdcol display_none">

                        {/* <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        <ul className="startxt starlistspacing">
						<li className="greentxt li-txt">Please check our <a href="https://www.facebook.com/wrtsroswellatlanta" target="_blank">Facebook</a> for daily OPEN PLAY hours or call ahead!</li>
                        <li className="greentxt">MONDAY - SATURDAY: 9AM - 6PM</li>
                        <li className="greentxt">SUNDAY: 10AM - 4:30PM</li> */}
                            {/* {post.acf.open_play_schedule_work_days.map(
                                (schedule, i) => (
                                    <li className="greentxt" key={i}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_day,
                                            }}
                                        />
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            {post.acf.open_play_schedule_weekend.map(
                                (schedule, i) => (
                                    <li className="bluetxt" key={`ii_${i}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_day,
                                            }}
                                        />
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            <p
                                className="pleasecallp"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf
                                            .open_play_please_call_ahead_text,
                                }}
                            /> */}
                        {/* </ul> */}
                    </div>

               {/*     <div className="onethirdcol full-row_heals old-heal">
                  
                        {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}
                        
                    </div>
                    <div className="onethirdcol full-row_heals">

                        {post.acf.open_play_register_widget_codes_copy.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_right,
                                    }}
                                />
                            )
                        )}

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>

                    <div className="onethirdcol full-row_heals great_deal_box">

                        {post.acf.open_play_register_widget_codes_grp.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_grp_single,
                                    }}
                                />
                            )
                        )}

                    </div>   */}
                    <div className="new-mg-bott"
                        dangerouslySetInnerHTML={{
                            __html: `<div class="elfsight-app-11f80384-a974-4aa8-9d23-4355d4e62d5a" data-elfsight-app-lazy></div>`,
                        }}
                    />
                    <div className='new_reg_place' style={{ marginTop: 0 }} >
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a> 
                    </div>
                    
                </div>
            </section>


{/* <section className="page-section bg-secondary text-white centersec">
<div className="container smallestwdt">

<h5>MONDAY - SATURDAY: 9AM - 6PM</h5>
<h5>SUNDAY: 10AM - 4:30PM</h5>

</div>
</section> */}
            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />
                </div>
            </section>
			
			{/* <section className="page-section bg-primary text-white centersec">
<div className="container smallestwdt">
<h2 className="yellowtxt">Private Facility Rental</h2>

<h5>- <span className="yellowtxt">$90</span> for <span className="yellowtxt">90</span> minutes</h5>
<h5>- Must be book <span className="yellowtxt">in advance</span></h5>
<h5>- Private use of the entire gym for <span className="yellowtxt">up to 10 individuals ($10 per additional person)</span></h5>
<h5>- Please contact the gym for <span className="yellowtxt">availability</span></h5>
</div>
</section> */}

            <section className="page-section reduce_padding_section">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <div className="popup-wrap op_popup">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/op_prebook.php" title="appointment" className="healcode_widgets">
                    </iframe>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_new_healcode_box
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes_grp {
                    open_play_register_widget_code_grp_single
                }
                open_play_register_widget_codes_copy {
                    open_play_register_widget_code_right
                }
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
